.inProgress:after {
  content: '';
  content: attr(data-content);
  font-size: 1.2em;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.8);
}

.cardActions {
  display: none;
}

.cardContainer:hover > .cardActions {
  display: flex;
}
