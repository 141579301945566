.App > * {
  height: calc(100vh - 64px);
}

body {
  overflow: hidden;
}

.bg-mic {
  background-image: url(../images/mic.jpg);
  background-size: cover;
  background-position: center;
}
