body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.btn {
  @apply font-bold py-2 px-4 rounded;
}
.btn-blue {
  @apply bg-blue-500 text-white;
}
.btn-blue:hover {
  @apply bg-blue-700;
}

.btn-success {
  @apply bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded
}

.btn-secondary {
  @apply bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded
}

/*.text-center {
  text-align: unset;
}*/

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.table-data {
  /* font-size: 22px; */
  /* position: relative; */
  /* top: 10px; */
  /* text-align: center; */
}

.page-bar {
  width: 100%;
}

.page-list {
  margin: 0 auto;
  position: relative;
  left: -5%;
}

.page-btn {
  font-family: Arial, Helvetica, sans-serif;
  margin: 3px;
  padding-left: 9px;
  padding-right: 9px;
  border-radius: 6px;
  font-weight: bolder;
}

.btn-row {
  display: flex;
  /* text-align: right; */
}

.btn-middle {
  width: 85%;
}

.container {
  /*position: relative;*/
  @apply w-full max-w-xs
  
  /* top: 20px; */
}

.form-group {
  @apply mb-4;
  margin-bottom: 1rem;
}

label {
  @apply block text-gray-700 text-sm font-bold mb-2
}


.create {
  position: relative;
  margin: 10px;
  font-weight: 800;
  font-family: Arial, Helvetica, sans-serif;
}

.btn {
  display: flex;
  white-space: nowrap;
}

.alert-text {
  color: crimson;
}

.page-bar {
  display: flex;
}

.page-list {
  display: flex;
  list-style: none;
}

.btn-active {
  background: cyan;
}

.btn-disactive {
  /* background: cyan; */
}

/* .with-icon {
  position: relative;
  top: 7px;
  margin-left: 3px;
  margin-right: 1px;
}

.icon-text {
  color: rgba(0, 0, 0, 0);
  font-size: 8px;
  visibility: hidden;
}

.btn-text {
  white-space: nowrap;
  position: relative;
  top: 3px;
  margin: 5px;
  font-size: 18px;
} */

.loading {
  margin: 0px auto;
  position: relative;
  /* top: 100px; */
  width: 150px;
}

.fas {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.sort {
  position: relative;
  top: 0;
  margin-right: 0;
}
