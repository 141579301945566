/****** Sidebar menu animation *********/

@media screen and (min-width: 640px) {
  #sidebar {
    transition: ease-in-out all 0.3s;
    transition-delay: 0.5s;
    z-index: 9999;
  }

  #sidebar span {
    opacity: 0;
    position: absolute;
    transition: ease-in-out opacity 0.5s;
  }

  #sidebar:hover span {
    opacity: 1;
    transition: ease-in-out opacity 0.9s;
    transition-delay: 0.5s;
  }
}
